import Vue from 'vue'
import VueRouter from 'vue-router'


import ABOUT from '@/views/about.vue'
import HOME from '@/views/homedata.vue'


import SERVICES from '@/views/services.vue'
import PROTFOLIO from '@/views/portfolio.vue'
import FAQ from '@/views/faq.vue'




Vue.use(VueRouter)

const routes = [
  {
    name:'home',
    path: '/',
    component: HOME,
  },
{
  name:'about',
  path: '/about',
  component: ABOUT,
},
{
  name:'services',
  path: '/services',
  component: SERVICES,
},
{
  name:'portfolio',
  path: '/portfolio',
  component: PROTFOLIO,
},
{
  name:'faq',
  path: '/faq',
  component: FAQ,
},


]



const router = new VueRouter({
  routes
})


/////////



router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
    //  console.log('nprogressstarted');
  }
  next()
 // console.log('nprogressstarted-next');

})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
 // console.log('nprogressstarted-done');

})



//////////

export default router
