
<template>
<div id="app">
		

<mainvav></mainvav>


<div >
<Loading></Loading>

<router-view/>

</div>


<mainfooter></mainfooter>



</div>
</template>





<script>

export default {
data() {
	return {
	//	name: 'hello',
		changeCss: false,

	}
},  


methods: {





},




}
</script>