import Vue from 'vue'

import main from './views/main.vue'

import router from './router'
import store from './store'



import '@/globalComponents'
import '@/localication'




Vue.config.productionTip = false


const a = new Vue({
  el: '#app',
  router,
  
  store,

  render: h => h(main)
})
