import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentlang: 'ar',
    ArActive: localStorage.getItem('lang') == 'ar' ? true : false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
