
<template>
    <div>
        
<main>
	<section class="welcome">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-lg-6">
					<div class="left">
						<h1>
							<span :class="{ MainSpain: this.$store.state.ArActive }" > {{$lang.messages.main_head_p1}} </span>
							<span :class="{ MainSpain: this.$store.state.ArActive }" > {{$lang.messages.main_head_spain_2}} </span>

						</h1>
						<p :class="{ MainSpainParg: isActive}">
								{{$lang.messages.main_head_p2}}
						</p>
						<div class="button">
							<a href="#" class="primary">{{$lang.messages.ask_for_smtg}}</a>
							<a href="#" class="second">{{$lang.messages.contact_us}}</a>
						</div>
						<div class="cubes">
							<span class="left-top cube-floating delay"></span>
							<span class="left-middle cube-floating"></span>
							<span class="left-bottom cube-floating delay"></span>
							<span class="right-top cube-floating"></span>
							<span class="right-bottom cube-floating delay"></span>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-6">

					<div class="animation">
						<div class="peoples">
							<span class="people-ma-four s-group"></span>
							<span class="people-ma-three s-group"></span>
									
							<span class="people-fe-two o-group"></span>
							<span class="people-ma-two o-group"></span>
							<span class="people-fe-three o-group"></span>
							<span class="desk o-group"></span>
							<span class="graph-two o-group"></span>

							<span class="graph-one l-group"></span>
							<span class="people-ma-one l-group"></span>
							<span class="people-fe-one l-group"></span>
						</div>
						<div class="blocks">
							<span class="right"></span>
							<span class="middle"></span>
							<span class="left-o"></span>
							<span class="left-l"></span>
							<span class="left-s"></span>
							<span class="l-t-cube"></span>
							<span class="sm-light l-group"></span>
							<span class="sm-cube down"></span>
							<span class="dr-cube"></span>
						</div>
						<div class="cube">
							<span class="box"></span>
							<span class="box-top delay-hidden"></span>
							<span class="box-light floating-second delay"></span>
							<span class="box-cubes"></span>
							<span class="box-left-stats"></span>
							<span class="box-right-stats"></span>
						</div>
					</div>
					<!--<div class="right">
						<div class="right-cube floating-one"></div>
						<div class="left-cube floating-one"></div>
						<div class="top-cube floating-second delay"></div>
						<div class="light floating-second"></div>
						<div class="cube floating-second"></div>
						<div class="background floating"></div>
					</div>-->
				</div>
			</div>
				
		</div>
	</section>
	
	<section class="featured-boxes">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4">
					<div class="item">
						<div class="icon dedicated"><i></i></div>
						<div class="head">Dedicated</div>
						<span class="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-4">
					<div class="item">
						<div class="icon development"><i></i></div>
						<div class="head">Development</div>
						<span class="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
					</div>
				</div>
				<div class="col-12 col-md-6 col-lg-4">
					<div class="item">
						<div class="icon branding"><i></i></div>
						<div class="head">Branding</div>
						<span class="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="this-is-rubikly">
		<div class="container">
			<div class="row">
				<div class="col-md-6">
					<div class="left">
						<div class="splash"></div>
						<div class="box"></div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="right">
						<h2>This is Rubikly!</h2>
						<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
						<div class="button"><a href="#">Primary Button</a></div>
						<div class="stats">
							<ul>
								<li>
									<span>82</span>
									<span>Count Sub Head</span>
								</li>
								<li>
									<span>82</span>
									<span>Count Sub Head</span>
								</li>
								<li>
									<span>82</span>
									<span>Count Sub Head</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="our-services">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="cubes">
						<span class="middle-left cube-floating-one"></span>
						<span class="middle-right cube-floating-second"></span>

						<span class="left-top cube-floating-one"></span>
						<span class="left-bottom cube-floating-second"></span>

						<span class="right-top cube-floating-second"></span>
						<span class="right-bottom cube-floating-one"></span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="main-head">
						<div class="bg-text">Rubikly</div>
						<h2>Our Services</h2>
						<div class="sub-title">Lorem ipsum dolar amet content</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="primary-featured">
						<div class="icon left"><i></i></div>
						<div class="head">Lorem ipsum was purposefully designed to have no meaning</div>
						<span class="description">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
					</div>
				</div>
				<div class="col-md-6">
					<div class="primary-featured">
						<div class="icon right"><i></i></div>
						<div class="head">Lorem ipsum was purposefully designed to have no meaning</div>
						<span class="description">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="all-features">
						<div class="head">All Features Head</div>
					</div>
				</div>
				<div class="col-4 col-md-2">
					<div class="features-item">
						<div class="box">
							<div class="content">
								<div class="white"><i class="server"></i></div>
							</div>
							<div class="name">Icon Head</div>
						</div>
					</div>
				</div>
				<div class="col-4 col-md-2">
					<div class="features-item">
						<div class="box">
							<div class="content">
								<div class="white"><i class="search"></i></div>
							</div>
							<div class="name">Icon Head</div>
						</div>
					</div>
				</div>
				<div class="col-4 col-md-2">
					<div class="features-item">
						<div class="box">
							<div class="content">
								<div class="white"><i class="role"></i></div>
							</div>
							<div class="name">Icon Head</div>
						</div>
					</div>
				</div>
				<div class="col-4 col-md-2">
					<div class="features-item">
						<div class="box">
							<div class="content">
								<div class="white"><i class="world"></i></div>
							</div>
							<div class="name">Icon Head</div>
						</div>
					</div>
				</div>
				<div class="col-4 col-md-2">
					<div class="features-item">
						<div class="box">
							<div class="content">
								<div class="white"><i class="group"></i></div>
							</div>
							<div class="name">Icon Head</div>
						</div>
					</div>
				</div>
				<div class="col-4 col-md-2">
					<div class="features-item">
						<div class="box">
							<div class="content">
								<div class="white"><i class="wreath"></i></div>
							</div>
							<div class="name">Icon Head</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="why-rubikly">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="cubes">
						<span class="left cube-floating-one"></span>
						<span class="right cube-floating-second"></span>
					</div>
				</div>
				<div class="col-md-12">
					<div class="main-head">
						<div class="bg-text">Rubikly</div>
						<h2>Content Heading</h2>
						<div class="sub-title">From its medieval origins to the digital era, learn everything there is to know about the ubiquitous</div>
					</div>
				</div>
				<div class="col-md-4">
					<div class="item">
						<div class="head">Lorem Ipsum Head</div>
						<span class="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
					</div>
				</div>
				<div class="col-md-4">
					<div class="item">
						<div class="head">Lorem Ipsum Head</div>
						<span class="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
					</div>
				</div>
				<div class="col-md-4">
					<div class="item">
						<div class="head">Lorem Ipsum Head</div>
						<span class="description">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</span>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="creative-boxes">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-lg-6 col-xl-5">
					<div class="left">
						<div class="small-head">What we do?</div>
						<div class="primary-head">Digital marketing agency on a mission</div>
						<div class="button"><a href="#">About us</a></div>
					</div>
				</div>
				<div class="col-md-12 col-lg-3">
					<div class="boxes o-l">
						<div class="item pink">
							<div class="icon"><div class="circle"><i class="icon-affiliate"></i></div></div>
							<div class="head">Features Name</div>
						</div>
						<div class="item purple">
							<div class="icon"><div class="circle"><i class="icon-graphic-design"></i></div></div>
							<div class="head">Features Name</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 col-lg-3">
					<div class="boxes o-r">
						<div class="item blue">
							<div class="icon"><div class="circle"><i class="icon-coding"></i></div></div>
							<div class="head">Features Name</div>
						</div>
						<div class="item green">
							<div class="icon"><div class="circle"><i class="icon-idea"></i></div></div>
							<div class="head">Features Name</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="slider-works">
		<div class="owl-carousel">
			<div class="slider">
				<div class="item"><img src="assets/img/slider-works/one.png"></div>
				<div class="item"><img src="assets/img/slider-works/second.png"></div>
				<div class="item"><img src="assets/img/slider-works/other.png"></div>
				<div class="item"><img src="assets/img/slider-works/last.png"></div>
			</div>
		</div>
	</section>

	<section class="build-future">
		<div class="container">
			<div class="row">
				<div class="col-12 col-lg-3">
					<div class="head">Build your Future!</div>
				</div>
				<div class="col-12 col-lg-7"><p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it.</p></div>
				<div class="col-12 col-lg-2"><div class="button"><a href="#">CTA Button</a></div></div>
			</div>
		</div>
	</section>
</main>

    </div>
</template>
<script>

export default {
	  name: 'homedata',
		data() {
			return {
					isActive : this.$store.state.ArActive ,
					arabicClasses: false,
			}
		},

created(){
console.log('this is from home data cuur lang --> '+this.$store.state.currentlang);

},

}


</script>