import Vue from 'vue'
//import router from './router'

//console.log(this.$route);
Vue.component('mainvav', 
    () => import('@/components/homeNav.vue')
)

Vue.component('Loading', 
    () => import('@/components/loading.vue')
)


Vue.component('mainfooter', 
() => import('@/components/footer.vue')
)